// eslint-disable-next-line
window.APP_CONF = {
    apiUrl              : 'https://az2.modelate.de',
    apiPrefix           : '/api/v1',
    clientId            : 'b7bf30b9-bb37-485a-a9ac-74a7b0bb6357',
    tenantId            : '798025fb-0198-44e6-bb73-b0a3557bec2f',
    check_debug         : true,
    codeChallenge       : 'kFjxyIJlnR_V-I2PermeIcgphfOShtbBlTbZ4Fab1k0',
    codeChallengeMethod : 'S256',
    redirectUrl         : 'https://az2.modelate.de/api/v1/auth/sign-in-callback',
    sentry              : {
        dsn              : '',
        tracesSampleRate : 1.0
    }
};
